@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'DFB Sans';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/font/dfb/dfb-sans-web-regular.eot);
  src: url(./assets/font/dfb/dfb-sans-web-regular.eot) format("eot"), url(./assets/font/dfb/dfb-sans-web-regular.woff2) format("woff2"), url(./assets/font/dfb/dfb-sans-web-regular.woff) format("woff"), url(./assets/font/dfb/dfb-sans-web-regular.ttf) format("truetype"); }
@font-face {
  font-family: 'DFB Sans';
  font-style: italic;
  font-weight: 400;
  src: url(./assets/font/dfb/dfb-sans-web-regular-italic.eot);
  src: url(./assets/font/dfb/dfb-sans-web-regular-italic.eot) format("eot"), url(./assets/font/dfb/dfb-sans-web-regular-italic.woff2) format("woff2"), url(./assets/font/dfb/dfb-sans-web-regular-italic.woff) format("woff"), url(./assets/font/dfb/dfb-sans-web-regular-italic.ttf) format("truetype"); }
@font-face {
  font-family: 'DFB Sans';
  font-style: normal;
  font-weight: 700;
  src: url(./assets/font/dfb/dfb-sans-web-bold.eot);
  src: url(./assets/font/dfb/dfb-sans-web-bold.eot) format("eot"), url(./assets/font/dfb/dfb-sans-web-bold.woff2) format("woff2"), url(./assets/font/dfb/dfb-sans-web-bold.woff) format("woff"), url(./assets/font/dfb/dfb-sans-web-bold.ttf) format("truetype"); }
@font-face {
  font-family: 'DFB Sans';
  font-style: italic;
  font-weight: 700;
  src: url(./assets/font/dfb/dfb-sans-web-bold-italic.eot);
  src: url(./assets/font/dfb/dfb-sans-web-bold-italic.eot) format("eot"), url(./assets/font/dfb/dfb-sans-web-bold-italic.woff2) format("woff2"), url(./assets/font/dfb/dfb-sans-web-bold-italic.woff) format("woff"), url(./assets/font/dfb/dfb-sans-web-bold-italic.ttf) format("truetype"); }


  html {
    scroll-behavior: smooth;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
